<template>
    <v-container>
        <div
            class="mb-4 pb-0"
        >
            <jumanjiGame
                v-if="game.game_type_id == 1"
                :game-id="gameId"
                :teams_count="game.teams_count"
            />
        </div>
    </v-container>
</template>

<script>
import axios from '@/plugins/axios'

export default {
    name: 'GameComponnent',
    components: {
        jumanjiGame: () => import('@/components/games/jumanji/jumanjiComponnent')
    },
    props: {
        gameId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            urlMain: 'admin/games/games/' + this.gameId,
            game: {
                id: '',
                game_type_id: '',
                teams_count: ''
            }
        }
    },
    computed: {

    },
    created() {
        this.fetchGame();
    },
    methods: {

        async fetchGame() {
            try {
                const game = await axios
                    .get(this.urlMain);

                this.initialize(game.data)
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        initialize(data) {
            this.game = data.game;
        }
    }
}
</script>

<style scoped>

</style>
